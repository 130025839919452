@import "~@/assets/scss/abstracts.scss";
#shopmall-apply {}

#shopmall-apply {padding-top:50px}
#shopmall-apply .btn-form {height:50px;padding:0 20px;height: 60px;}

#shopmall-apply .apply-section{position:relative;}
#shopmall-apply .apply-section .section-header {text-align:center;margin-bottom:30px}
#shopmall-apply .apply-section .section-header .title {white-space:nowrap;font-size:30px;font-weight:800}

#shopmall-apply .round-tabs {display:flex;justify-content:center;padding-top:0;padding-bottom:10px}
#shopmall-apply .round-tabs .tab-list {display:inline-flex;padding:5px;border-radius:30px;background-color:#ebebeb}
#shopmall-apply .round-tabs .tab-item {}
#shopmall-apply .round-tabs .tab-link {position:relative;display:inline-block;padding:15px 70px;border-radius:25px;min-width:200px;text-align:center}
#shopmall-apply .round-tabs .tab-text {font-size:clamp(17px,1.8vw,15px);font-weight:800;white-space:nowrap;vertical-align:middle;color:#555}
#shopmall-apply .round-tabs .is-selected {}
#shopmall-apply .round-tabs .is-selected .tab-link {background-color:#ffffff}
#shopmall-apply .round-tabs .is-selected .tab-text {color:#111}
#shopmall-apply .round-tabs.is-fluid {}
#shopmall-apply .round-tabs.is-fluid .tab-list {flex:1;}
#shopmall-apply .round-tabs.is-fluid .tab-item {width:50%;}
#shopmall-apply .round-tabs.is-fluid .tab-link {display:block;text-align:center;}
#shopmall-apply .round-tabs + .section {margin-top:50px}


#shopmall-apply .form-section {display:flex;padding-top:40px}
#shopmall-apply .form-section .section-header {padding-top:15px;min-width:216px;}
#shopmall-apply .form-section .section-header .title {font-size:15px;color:#015aa8;font-weight:800}
#shopmall-apply .form-section .form-field {flex:auto}
#shopmall-apply .form-section .form-celltd {max-width:560px; padding-left: 10px;}
#shopmall-apply .form-section .form-celltd .form-row > .btn {height:43px;flex:0 0 160px;width:160px;margin-left:20px;white-space:nowrap;}
#shopmall-apply .form-section.bordered {border-top:1px solid #ebebeb;padding-top:30px;margin-top:30px}
#shopmall-apply .form-celltd .form-select {max-width:380px}

#shopmall-apply .form-celltd > .form-check-group {white-space:nowrap;}

#shopmall-apply .apply-guide {margin-bottom:80px;}
#shopmall-apply .apply-guide .guide-list {display:flex;justify-content:space-around;padding-top:7px}
#shopmall-apply .apply-guide .guide-item {display:flex;flex-direction:column;align-items:center;}
#shopmall-apply .apply-guide .guide-item .text {font-size: 17px;}
#shopmall-apply .apply-guide .guide-item .header {font-size: 17px;}
#shopmall-apply .apply-guide .step {display:inline-block;font-weight:800;color:#015aa8;margin-bottom:20px}
#shopmall-apply .apply-guide [class^="icon-guide"] {width:60px;height:60px;margin-bottom:8.5px}
#shopmall-apply .apply-guide .icon-guide-01 {background-image:url(../img/apply/icon_apply_01.svg)}
#shopmall-apply .apply-guide .icon-guide-02 {background-image:url(../img/apply/icon_apply_02.svg)}
#shopmall-apply .apply-guide .icon-guide-03 {background-image:url(../img/apply/icon_apply_03.svg)}
#shopmall-apply .apply-guide .icon-guide-04 {background-image:url(../img/apply/icon_apply_04.svg)}
#shopmall-apply .apply-guide .header {font-weight:800;display:block;margin-bottom:20px;padding-top:10px}
#shopmall-apply .apply-guide .text {text-align:center; color:var(--text-muted);line-height:1.2;}

#shopmall-apply .contract-join-wrapper {display:flex;justify-content:space-between;padding-top:15px}
#shopmall-apply .contract-join-wrapper .join-content {}
#shopmall-apply .contract-join-wrapper .join-content .header {font-weight:800;margin-bottom:5px}
#shopmall-apply .contract-join-wrapper .join-content .text {font-size:13px;line-height:1.6}

#shopmall-apply .privacy-agree-wrapper {}
#shopmall-apply .privacy-agree-wrapper .privacy-content {line-height:1.6}
/* #shopmall-apply .privacy-agree-wrapper .agree-check {padding-top:30px} */

#shopmall-apply .terms-agree-wrapper {padding-top:0;margin-top:-10px}
#shopmall-apply .terms-agree-wrapper .terms-content {border:1px solid #ebebeb;border-radius:.3125rem;padding:20px;max-height:150px;overflow-y:auto}
#shopmall-apply .terms-agree-wrapper .terms {font-size:17px;}
#shopmall-apply .terms-agree-wrapper .terms .text {line-height:1.6}
#shopmall-apply .terms-agree-wrapper .terms .header {margin-bottom:10px}
#shopmall-apply .terms-agree-wrapper .terms .header:not(:first-child) {padding-top:20px}
#shopmall-apply .terms-agree-wrapper .terms .description {margin-bottom:0}
#shopmall-apply .terms-agree-wrapper .agree-check {padding-top:20px}


#shopmall-apply .form-check-label {font-size: 17px;}
/*#shopmall-apply .privacy-agree-wrapper .agree-check {padding-top:20px}*/

#shopmall-apply input[type="date"] {height:50px;}
#shopmall-apply input[type="date"]:before {top:13px;left:8px}


@media (max-width:1023.98px) {
    #shopmall-apply .apply-guide .step,
    #shopmall-apply .apply-guide .header,
    #shopmall-apply .apply-guide .text {font-size:14px}
    #shopmall-apply .apply-section .section-header .title {font-size:18px}

    #shopmall-apply .apply-guide .step {margin-bottom:15px}
    #shopmall-apply .apply-guide .header {margin-bottom:15px;}
    #shopmall-apply .apply-guide {margin-bottom:60px}
}

@media (max-width:767.98px) {
    #shopmall-apply {padding-top:0}
    #shopmall-apply .section-procedure .section-header {display:none}
    #shopmall-apply .section-form {padding-top:40px}

    #shopmall-apply .apply-guide {margin:0 calc(25px * -1) 0;background-color:#f3f5f7;padding:30px 25px;}
    #shopmall-apply .apply-guide .guide-list {padding-top:0}
    #shopmall-apply .apply-guide .guide-item {position:relative;flex:1;}
    #shopmall-apply .apply-guide .guide-item:after {content:'';position:absolute;top:2px;left:100%;display:block;width:6px;height:10px;background:url(../img/apply/icon_procedure_arrow.svg) no-repeat 0 0;transform:translateX(-50%);}
    #shopmall-apply .apply-guide .guide-item:last-child:after {display:none}
    #shopmall-apply .apply-guide .step, #shopmall-apply .apply-guide .header, #shopmall-apply .apply-guide .text {font-size:13px;}
    #shopmall-apply .apply-guide .step {margin-bottom:10px;color:var(--text-muted)}
    #shopmall-apply .apply-guide [class^="icon-guide"] {width:30px;height:30px;margin-bottom:11px;background-size:30px auto}
    #shopmall-apply .apply-guide .text {display:none}
    #shopmall-apply .apply-guide .header {margin-bottom:0;padding-top:0}

    #shopmall-apply .round-tabs .tab-link {min-width:116px;padding:15px 0;width:116px}

    #shopmall-apply .apply-section .section-header .title {font-size:20px;}
    #shopmall-apply .form-section {display:block;padding-top:40px;}
    #shopmall-apply .form-section .section-header {padding-top:0;margin-bottom:20px;}
    #shopmall-apply .form-section .section-header .title {font-size:15px;text-align:left}
    #shopmall-apply .form-section.bordered {padding-top:50px;margin-top:0;border-top:0}
    #shopmall-apply .form-celltd > .form-check-group {white-space:inherit;padding-top:0}
    #shopmall-apply .form-row .form-cellth {width:75px}
    #shopmall-apply .form-row .form-cellth.is-required .text:after {right:inherit;left:-8px;}
    #shopmall-apply .form-help .text {font-size:11px}
    #shopmall-apply .field-upload .form-cellth {width:135px}
    #shopmall-apply .field-upload .form-file-label {width:100%;line-height:46px}
    #shopmall-apply .field-upload .form-file-label .text {font-size:13px;font-weight:800;color:#015aa8}
    #shopmall-apply .form-select {max-width:100% !important}
    #shopmall-apply .field-btn .form-row {flex-wrap:wrap}
    #shopmall-apply .field-btn .form-celltd .form-row > .btn {height:34px;margin-left:0;width:130px;margin-top:10px;margin-bottom:10px}

    #shopmall-apply .contract-join-wrapper {display:block;padding-top:0}
    #shopmall-apply .contract-join-wrapper .join-content .header {font-size:13px;line-height:1.6}
    #shopmall-apply .contract-join-wrapper .join-content .text {font-size:11px;}
    #shopmall-apply .contract-join-wrapper .join-buttons {margin-top:20px}
    #shopmall-apply .contract-join-wrapper .join-buttons .btn {width:126px}
    #shopmall-apply .contract-join-wrapper .join-buttons .text {font-size:11px}

    #shopmall-apply .help-secondary {padding-top:0;color:#ff7300;}

    #shopmall-apply .privacy-agree-wrapper {padding-top:0;font-size:13px}
    #shopmall-apply .privacy-agree-wrapper dd {margin-bottom:0}

}

@media (max-width:575.98px) {
    #shopmall-apply .v-sm {display:block}
}

@media (min-width:320px) and (max-width:1023.98px) {}



#shopmall-apply .file-list-container {position:relative;padding-top:10px;}
#shopmall-apply .file-list-container .file-item {display:flex; padding-left: 44%;}
#shopmall-apply .file-list-container .file-data {padding-left:20px}
#shopmall-apply .file-list-container .file-data .text {font-size:12px;font-weight:600}
#shopmall-apply .file-list-container .image {position:relative;width:275px;height:170px;}
#shopmall-apply .file-list-container .image img {width:100%;height:100%;object-fit:cover;}
#shopmall-apply .file-list-container .image img {width:100%;height:100%;object-fit:cover;}
#shopmall-apply .file-list-container .image .btn {position:absolute;bottom:10px;right:10px}
#shopmall-apply .file-list-container .image ~ .file-data {display:flex;align-items:flex-end}
#shopmall-apply .file-list-container.is-floating {position:absolute;right:20px;top:0;padding-top:20px}
#shopmall-apply .file-list-container.is-floating .image{width:350px;height:200px}
#shopmall-apply .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

#shopmall-apply .page-buttons.is-wide .btn {
    min-width: 270px;
    height: 70px;
    padding-left: 30px;
    padding-right: 30px;
}

#shopmall-apply .page-buttons.is-wide .text {
    font-size: 20px;
    font-weight: 800;
}

/* 레이아웃 수정 */
.layout_pc{display: block;}
#shopmall-apply .form-section{flex-direction: column;align-items: flex-start;} 
#shopmall-apply .form-section .section-header{width: 100%; padding-bottom: 15px; border-bottom: 3px solid #015aa8; text-align: left;}
#shopmall-apply .form-section .section-header .title{font-size: 21px;}
#shopmall-apply .form-section .section-header .title .required {font-size: 17px; color: red; padding-left: 25px; vertical-align: bottom;}
#shopmall-apply .form-section .section-header .title .required::before {content:''; margin-top: 5px; margin-left: -15px; background-color: red; position: absolute; width: 10px; height: 10px; border-radius: 5px;}
#shopmall-apply .form-section .form-field{display: flex; flex-wrap: wrap;}
#shopmall-apply .form-section .form-field>.form-row{width: 31%; margin-left: 1%; margin-bottom: 20px;}
#shopmall-apply .form-section .form-row ~ .form-row:not(.value){padding-top: 0;}
#shopmall-apply .form-section .form-field>.form-row.w_100{width: 100%;}
#shopmall-apply .form-section .form-field>.form-row.w_50{width: 55%;}
#shopmall-apply .form-section .form-field>.form-row.w_100 .form-celltd{max-width: calc(100% - 600px);}
#shopmall-apply .form-section .form-field>.form-row.w_100 .form-check{padding-bottom: 20px;}
.field-address .form-row:nth-child(2){margin: 10px 0;}
#shopmall-apply .form-row .form-cellth{width: 140px; text-align: center;}
#shopmall-apply .sub-header{width: 100%;padding: 15px 0;text-align: left;}
#shopmall-apply .sub-header .title{font-size: 18px; font-weight: bolder;}
#shopmall-apply .agreement_info .header{width: 100%;}
#shopmall-apply .agreement_info .form-row{display: block;}
#shopmall-apply .agreement_info .terms-agree-wrapper{margin: 0;}
#shopmall-apply .agreement_info .privacy-agree-wrapper .privacy-content{font-size: 17px; display: flex;padding: 20px; border: 1px solid #ebebeb;flex-wrap: wrap;}
#shopmall-apply .agreement_info .privacy-agree-wrapper .privacy-content .header{width: 20%; }
#shopmall-apply .agreement_info .privacy-agree-wrapper .privacy-content .header ~ .header{margin-top: 10px;}
#shopmall-apply .agreement_info .privacy-agree-wrapper .privacy-content .description{width: 80%;}
#shopmall-apply .agreement_info .privacy-agree-wrapper .privacy-content .description ~ .description{margin-top: 10px;}
#shopmall-apply .agreement_info .privacy-agree-wrapper .agree-check{padding-top: 20px;}
#shopmall-apply .form-control{padding: 12px 15px; font-size: 17px;}
#shopmall-apply .form-control.Url{width: 266%;}
#shopmall-apply .form-cellth label{height: 50px; font-size: 17px;}
#shopmall-apply .form-hyphen{line-height:40px; opacity: 0.3; font-weight:900;}
#shopmall-apply .form-email{opacity: 0.3; margin: auto 2px;}

@media (min-width:767px) and (max-width: 1500px) {
    .layout_pc{display: none;}
    #shopmall-apply .form-row .form-cellth{text-align-last: left;}
    #shopmall-apply .form-section .form-field>.form-row.w_100 .form-celltd{max-width: 100%;}
    #shopmall-apply .form-row.ta_row{width: 100%;}
    #shopmall-apply .form-section .ta_row .form-celltd{max-width: 100%;}
    #shopmall-apply .form-row.ta_row .form-control{width: 32%;}
    #shopmall-apply .form-section .form-field>.form-row.w_50{width: 100%;}
    #shopmall-apply .form-control.Url{width: 203%;}
}

@media (max-width:767.98px) {
    .layout_pc{display: none;}
    #shopmall-apply .form-section .form-field{display: block;}
    #shopmall-apply .form-section .form-field>.form-row{width: 100%; margin-left: 0;}
    #shopmall-apply .form-section .form-field>.form-row.w_100{width: 100%;}
    #shopmall-apply .form-section .form-field>.form-row.w_50{width: 100%;}
    .mo_row .form-control{width: 30%;}
    #shopmall-apply .form-section .form-field>.form-row.w_100 .form-celltd{max-width: 560px;}
    #shopmall-apply .form-row .form-cellth{text-align: left;}
    #shopmall-apply .form-control.Url{width: 100%;}
}